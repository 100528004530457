<template>
  <div
    class="flex h-screen"
  />
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import router from "../../router";

const {mapMutations: videoMutations} = createNamespacedHelpers('video')
const {mapState: streamSeatState} = createNamespacedHelpers('streamSeat')
const {mapMutations: editorMutations} = createNamespacedHelpers('editor')
const {mapActions: streamSeatActions} = createNamespacedHelpers('streamSeat')
const {mapMutations: streamSeatMutations} = createNamespacedHelpers('streamSeat')

export default {
  name: "Home",
  data: function () {
    return {
      record: null
    }
  },
  computed: {
    ...streamSeatState({
      seat_id: state => state.seat_id,
      download_url: state => state.download_url
    }),
  },

  mounted() {
  },

  created: function () {
    console.log('stream home yaba')
    this.initParams()

    // TODO: either we go to session expired
    //  or go to the download page
    //  or go to the editor
    //  or go to preview

    // Check first if we have record with the stream_seat_id
    this.getRecord().then((response) => {
      this.record = response.data.seat
      if (this.shouldRedirectToSessionExpiredPage()) {
        router.push({ name: 'session-expired' })
      } else if (this.shouldGoToDownloadPage()) {
        this.goToDownloadPage()
      } else if (this.shouldGoToPreviewPage()) {
        this.setIsStream(true)
        router.push({name: 'preview'})
      } else {
        // go to editor
        this.goToEditor()
      }
    })
  },

  methods: {
    ...streamSeatActions({
      getRecord: 'getRecord',
      setStreamData: 'setData',
      createRecord: 'createRecord'
    }),
    ...streamSeatMutations({
      setSeatId: 'setSeatId',
      setIsDownloading: 'setIsDownloading'
    }),
    ...editorMutations({
      setOptions: 'setOptions',
      setIsStream: 'setIsStream'
    }),
    ...videoMutations({
      setVideo: 'setVideo'
    }),
    initParams: function () {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      const seat_id = params.get('seat_id');
      const download_url = params.get('download_url');

      this.setStreamData({
        seat_id: seat_id,
        download_url: download_url
      })
    },
    shouldGoToDownloadPage: function () {
      return !this.record || !this.record.video_url;
    },
    goToDownloadPage: function () {
      if (!this.record) {
        this.createRecord().then(() => {
          router.push({ name: 'DownloadVideo' })
        }).catch(() => {
          this.$notify({
            title: 'Error',
            type: 'error',
            text: 'Something went wrong'
          })
        })
      } else {
        // we set the is_downloading flag, to check it later in the download page.
        // if the is_downloading of the record is already downloading,
        // then the record in queue already being downloaded. We should not download it again.
        this.setIsDownloading(this.record.is_downloading)
        router.push({ name: 'DownloadVideo' })
      }
    },
    shouldGoToPreviewPage: function () {
      return (this.record && !this.record.exception_message && !this.record.is_deactivated && (this.record.is_processed || this.record.is_under_processing) && !this.record.is_state_saved);
    },
    shouldRedirectToSessionExpiredPage: function () {
      return (this.record && this.record.is_deactivated)
    },
    goToEditor: function () {
      console.log('go to editor')
      this.setIsStream(true)

      if (this.record) {
        this.setOptions(this.record.options)
      }
      this.setVideo({
        duration: this.record.duration,
        url: this.record.video_url,
        type: 'video/mp4',
        fast_url: this.record.video_url
      })
      router.push({ name: 'editor' })
    }
  }
}
</script>

<style scoped>

</style>
