<template>
  <div class="w-full h-full">
    <video
      :ref="reference"
      :autoplay="false"
      class="video-js w-full h-full"
    />
  </div>
</template>

<script>

import videojs from 'video.js'
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers('video')

export default {
  name: 'VideoPlayer',

  props: {
    reference: {
      type: String,
      default () {
        return 'videoPlayer'
      }
    }
  },
  emits: ['loadeddata', 'ended', 'seeked'],
  data () {
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: null,
            type: null,
          }
        ]
      }
    }
  },

  computed: {
    ...mapState({
      video: state => state.video,
      current_time: state => state.current_time
    })
  },

  watch: {
    video: {
      handler(val){
        if(val.url && val.type) {
          this.videoOptions = {
            autoplay: false,
            controls: true,
            sources: [
              {
                src: val.fast_url ? val.fast_url : val.url,
                type: val.type,
              }
            ]
          }
          this.initialiseVideo()
        }
      },
      // immediate: true
    },

    current_time: {
      handler(val){
        this.player.currentTime(val)
      }
    }
  },
  mounted() {
    if (this.video.url && this.video.type) {
      this.videoOptions = {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.video.fast_url ? this.video.fast_url : this.video.url,
            type: this.video.type,
          }
        ]
      }
      this.initialiseVideo()
    }
  },
  methods: {
    initialiseVideo () {
      const localThis = this
      this.player = videojs(this.$refs[this.reference], this.videoOptions, function onPlayerReady () {

      })

      this.player.on('loadeddata', function () {
        localThis.$emit('loadeddata', this.player, this)
      })

      this.player.on('seeked',function (){
          localThis.$emit('seeked', localThis.$refs[localThis.reference]);
      })

      this.player.on('ended', function () {
        localThis.$emit('ended', this.player)
      })
    }
  }
}
</script>

<style scoped>

</style>
