
// Methods
export function convertHMS (value) {
  const milliseconds = (value+"").split(".")[1]
  const sec = parseInt(value, 10) // convert value to number if it's string
  let hours = Math.floor(sec / 3600) // get hours
  let minutes = Math.floor((sec - (hours * 3600)) / 60) // get minutes
  let seconds = sec - (hours * 3600) - (minutes * 60) //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) { hours = '0' + hours }
  if (minutes < 10) { minutes = '0' + minutes }
  if (seconds < 10) { seconds = '0' + seconds }
  return hours + ':' + minutes + ':' + seconds + (milliseconds ? '.' + milliseconds : '') // Return is HH : MM : SS
}

/**
 * @param value
 * @throws Error
 * @description transform the HMS text to seconds
 */
export function convertHMSToS(value) {
  const valueArr = value.split(':');
  if (valueArr.length !== 3) {
    throw Error('Invalid format')
  }
  const hour = parseFloat(valueArr[0]);
  const min = parseFloat(valueArr[1]);
  const sec = parseFloat(valueArr[2]);

  if (min > 60 || sec > 60) {
    throw Error('Invalid format')
  }
  return (hour * 3600) + (min * 60) + sec
}
