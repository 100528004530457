import { createApp } from 'vue'
import App from './App.vue'

// css
import './assets/css/base.css'
import 'video.js/dist/video-js.min.css'
import 'vue-slider-component/theme/default.css'
import 'dropzone-vue/dist/dropzone-vue.common.css';
import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// vuex
import store from "./vuex/store"

//notification
import Notifications from '@kyvg/vue3-notification'

// PrimeVue and components
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'

// router
import router from './router'

createApp(App).use(router)
  .use(store)
  .use(Notifications)
  .use(PrimeVue)
  .use(ConfirmationService)
  .mount('#app')
