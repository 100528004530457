<template>
  <div v-if="duration">
    <div v-if="durationLayout === 'top'">
      <div
        class="flex justify-between mb-2"
      >
        <div
          :class="opacityClass"
          class="flex-initial w-14 font-semibold text-sm text-gray-700"
        >
          <input
            v-model="firstIndexDuration"
            class="w-full font-semibold text-xs text-black-victor border-0 ring-0 p-0"
            type="text"
            @change="onInputChange($event, 0)"
          >
        </div>

        <div
          :class="opacityClass"
          class="flex-initial w-14 font-semibold text-sm text-gray-700"
        >
          <input
            v-model="secondIndexDuration"
            class="w-full font-semibold text-xs text-black-victor border-0 ring-0 p-0"
            type="text"
            @change="onInputChange($event, 1)"
          >
        </div>
      </div>

      <div class="w-full">
        <vue-slider
          ref="slider"
          v-model="sliderModel"
          :min="start"
          :max="endTime"
          :class="opacityClass"
          tooltip="none"
          :enable-cross="false"
          :interval="0.01"
          @change="change"
          @dragging="dragging"
          @drag-end="dragEnd"
          @drag-start="dragStart"
        />
      </div>
    </div>

    <div
      v-else-if="durationLayout === 'side'"
      class="flex items-center"
    >
      <div
        :class="opacityClass"
        class="text-left flex-none w-20"
      >
        <input
          v-model="firstIndexDuration"
          class="w-full font-semibold text-xs text-black-victor border-0 ring-0 p-0"
          type="text"
          @change="onInputChange($event, 0)"
        >
      </div>

      <div class="flex-grow px-4">
        <vue-slider
          ref="slider"
          v-model="sliderModel"
          :clickable="false"
          :value="model"
          :min="start"
          :max="endTime"
          :class="opacityClass"
          tooltip="none"
          :enable-cross="false"
          :interval="0.01"
          @change="change"
          @dragging="dragging"
          @drag-end="dragEnd"
          @drag-start="dragStart"
        />
      </div>

      <div
        :class="opacityClass"
        class="text-right flex-none w-20"
      >
        <input
          v-model="secondIndexDuration"
          class="w-full font-semibold text-xs text-black-victor border-0 ring-0 p-0"
          type="text"
          @change="onInputChange($event, 1)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import { createNamespacedHelpers } from "vuex";
import {convertHMS, convertHMSToS} from '../helpers.js'
const { mapMutations } = createNamespacedHelpers('video')


export default {
  name: 'VideoInputSlider',
  components: {
    VueSlider
  },
  props: {
    durationLayout: {
      type: String,
      default () {
        return 'top'
      }
    },
    model: {
      type: Array,
      default () {
        return []
      }
    },
    duration: {
      required: true,
      type: Number
    },
    /**
     * If we have minProp and max prop we make sure sliderModel values
     * is not outside minProp and maxProp
     */
    min: {
      type: Number,
      default () {
        return 0
      }
    },
    max: {
      type: Number,
      default () {
        return 0
      }
    },
    active: {
      type: Boolean,
      default () {
        return true
      }
    },
    start: {
      type: Number,
      default () {
        return 0
      }
    },
    end: {
      type: Number,
      default () {
        return 0
      }
    }
  },
  emits: ['change', 'drag-end', 'update:model'],
  data: function () {
    return {
      latestValue : null,
      latestIndex: null,
      latestInputValue: null,
      latestInputEvtValue: null,
      latestInputValueTimer: null,
      isChanging: false,
      changingInput: null,
      changingIndex: null,
      firstIndexDuration: null,
      secondIndexDuration: null,
    }
  },

  computed: {
    sliderModel () {
      if (this.model && this.model.length) {
        return  this.model
      } else if (!this.hasMinMax) {
        return [this.start, this.endTime]
      } else {
        return [this.min, this.max]
      }
    },
    hasMinMax () {
      return this.min || this.max
    },
    opacityClass () {
      return this.active ? 'opacity-100' : 'opacity-30'
    },
    endTime () {
      return this.end !== 0 ? this.end : this.duration
    }
  },

  watch: {
    sliderModel: {
      handler(val) {
        this.firstIndexDuration = convertHMS(val[0])
        this.secondIndexDuration = convertHMS(val[1])
        this.$emit('update:model', val)
      },
      immediate: true,
    },
    min: {
      handler() {
        this.adjustSliderModelPerMinMax(this.sliderModel)
      }
    },
    max: {
      handler() {
        this.adjustSliderModelPerMinMax(this.sliderModel)
      }
    },
    start: {
      handler () {
        this.adjustSliderModelPerStartEnd(this.sliderModel)
      }
    },
    end: {
      handler () {
        this.adjustSliderModelPerStartEnd(this.sliderModel)
      }
    },
  },

  mounted() {
  },

  methods: {
    setModelDefault: function () {
      if (!this.model.length) {
        this.sliderModel = [0, this.duration]
      } else {
        this.sliderModel = this.model
      }
    },
    change: function (value, index) {
      this.latestValue = value
      this.latestIndex = index

      if(!this.isChanging) {
        this.isChanging = true
        setTimeout(() => {
          if (!this.hasMinMax) {
            this.setCurrentTime(this.latestValue[this.latestIndex])
            this.$emit('update:model', this.latestValue)
          } else {
            // Don't change unless inside the min and max
            if (this.latestValue[this.latestIndex] > this.min && this.latestValue[this.latestIndex] < this.max) {
              this.setCurrentTime(this.latestValue[this.latestIndex])
              this.$emit('update:model', this.latestValue)
            }
          }
          this.isChanging = false
        },500)

      }
    },
    dragging: function () {
      // this.adjustSliderModelPerMinMax(value)
    },
    dragEnd: function () {
      this.$emit('drag-end')
    },
    dragStart: function () {

    },
    ...mapMutations({
      setCurrentTime: 'setCurrentTime'
    }),
    /**
     * Adjusting the slider model [min,max]
     * if we have from prop minProp and maxProp and this normally is dynamic.
     * We want the sliderModel not to be outside the props
     */
    adjustSliderModelPerMinMax: function (value) {
      if(this.hasMinMax && value[0] <= this.min) {
        const val = [parseFloat(this.min), parseFloat(value[1])]
        this.sliderModel = val.slice(0)
        this.$refs.slider.setValue(val)
        this.$emit('update:model', val)
      }
      if(this.hasMinMax && parseFloat(value[1]) > parseFloat(this.max)) {
        const val = [parseFloat(value[0]), parseFloat(this.max)]
        this.sliderModel = val.slice(0)
        this.$refs.slider.setValue(val)
        this.$emit('update:model', val)
      }
    },
    adjustSliderModelPerStartEnd: function (value) {
      if (this.start !== 0 && value[0] <= this.start) {
        const val = [parseFloat(this.start), parseFloat(value[1])]
        this.sliderModel = val.slice(0)
        this.$refs.slider.setValue(val)
        this.$emit('update:model', val)
      }
      if (this.end !== 0 && value[1] > parseFloat(this.end)) {
        const val = [parseFloat(value[0]), parseFloat(this.end)]
        this.sliderModel = val.slice(0)
        this.$refs.slider.setValue(val)
        this.$emit('update:model', val)
      }

      // const val = [parseFloat(this.start), parseFloat(this.end)]
      // this.sliderModel = val.slice(0)
      // this.$refs.slider.setValue(val)
      // this.$emit('update:model', val)
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;

      if(charCode === 58) {
        return true;
      }
      else if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        return false;
      } else {
        return true;
      }
    },
    onInputChange(evt,index) {
      // validate that the value is between our range
      // start and end
      // create function that transform the text into seconds and update the models
      const value = evt.target.value;
      try {
        this.latestInputEvtValue = convertHMSToS(value)

        if (this.latestInputEvtValue < this.start || this.latestInputEvtValue > this.endTime) {
          throw new Error('Invalid Range')
        }

        // make sure that min is not more than max
        if (index === 0 && this.latestInputEvtValue > this.sliderModel[1]) {
          throw new Error('Min can not be greater then Max')
        }

        if (this.changingIndex === 1 && this.latestInputEvtValue < this.sliderModel[0]) {
          throw new Error('Max can not be less then Min')
        }

        if (index === 0) {
          this.latestInputValue = [convertHMSToS(value), this.sliderModel[1]]
        } else if (index === 1) {
          this.latestInputValue = [this.sliderModel[0], convertHMSToS(value)]
        }

        this.$refs.slider.setValue(this.latestInputValue)

      } catch (err) {
        // reset value in index with old value
        if (index === 0) {
          this.firstIndexDuration = convertHMS(this.sliderModel[0])
        } else if (index === 1) {
          this.secondIndexDuration = convertHMS(this.sliderModel[1])
        }
        evt.preventDefault();
        this.$notify({
          title: 'Error',
          type: 'error',
          text: err.message
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
