import http from '../http-common'

class VideoDataService {
  saveAndPublish (data) {
    // eslint-disable-next-line no-undef
    return http.post(process.env.BASE_URL+'api/video/save-and-publish', data)
  }
  validate (data) {
    // eslint-disable-next-line no-undef
    return http.post('/api/video/generate-validation', data)
  }
  isSeatValid (recordId) {
    return http.get(`/api/video/check-record-validation/${recordId}`)
  }
  getVideoById (id) {
    return http.get(`/api/video/get-video/${id}`)
  }
  publishVideo (id) {
    return http.put(`/api/video/publish-video/${id}`)
  }
  cancelVideo (id) {
    return http.put(`/api/video/cancel-video/${id}`)
  }
  deleteRecord (id) {
    return http.delete(`/api/video/delete-record/${id}`)
  }
  addOptions (id, data) {
    return http.put(`/api/video/add-record-options/${id}`, {
      options: data
    })
  }
  initRecord (data) {
    return http.post('/api/video/init-record', data)
  }
}

export default new VideoDataService()
