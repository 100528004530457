import sharedService from "../../api/services/sharedService";

const state = {
  options: null,
  is_stream: null
}

const mutations = {
  setOptions(state, options) {
    state.options = options
  },
  setIsStream(state, is_stream) {
    state.is_stream = is_stream
  }
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  uploadImage({state},image) {
    console.log(image)
    if ((typeof image === 'string' || image instanceof String)) {
      return new Promise((resolve, reject) => {
        try {
          resolve({
            data: {
              image: image
            }
          })
        } catch (err) {
          console.log(err)
          reject(err)
        }
      })
    } else {
      const formData = new FormData();
      formData.append("image", image);  // appending file
      return sharedService.uploadImage(formData)
    }
  },
}

const getters = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
