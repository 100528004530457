import videoDataService from "../../api/services/videoDataService";

const state = {
  url: null,
  type: null,
  duration: null,
  fast_url: null,
  seat_id: null,
  video_seat_id: null,
}

const mutations = {
  setData(state, data) {
    state.url = data.url
    state.type = data.type
    state.duration = data.duration
    state.fast_url = data.fast_url
    state.seat_id = data.seat_id
  },
  setStreamData(sate, data) {
    state.seat_id = data.seat_id
  },
  setVideoSeatId(state, id) {
    state.video_seat_id = id
  },
}

const actions = {
  getRecordById({state, commit}) {
    return videoDataService.getVideoById(state.seat_id).then((response) => {
      const record = response.data.record
      if (record) {
        commit('setVideoSeatId', record.id)
      }
      return new Promise((resolve, reject) => {
        try {
          resolve(response)
        } catch (err) {
          reject(err)
        }
      })
    })
  },
  initRecord({state, commit, getters}) {
    return videoDataService.initRecord({
      seatId: state.seat_id,
      inputs: getters.getData
    }).then((response) => {
      commit('setVideoSeatId', response.data.id)
      return new Promise((resolve, reject) => {
        try {
          resolve(response)
        } catch (err) {
          reject(err)
        }
      })
    })
  },
  validateSeat({state}) {
    return videoDataService.isSeatValid(state.video_seat_id)
  },
  saveEditorsOptions({state}, options) {
    return videoDataService.addOptions(state.video_seat_id, options)
  },
  cancelVideoRecord({state}) {
    return videoDataService.cancelVideo(state.video_seat_id)
  },
  deleteRecord({state}) {
    return videoDataService.deleteRecord(state.video_seat_id)
  },
  publishRecord({state}) {
    return videoDataService.publishVideo(state.video_seat_id)
  },
}

const getters = {
  getData: (state) => {
    return {
      url: state.url,
      type: state.type,
      duration: state.duration,
      fast_url: state.fast_url,
      seat_id: state.seat_id,
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
