<template>
  <div class="flex h-screen">
    <div class="my-auto w-full">
      <div
        class="text-center grid grid-cols-12 gap-4"
      >
        <div class="col-start-5 col-span-4">
          <span class="text-xs font-semibold text-gray-victor">Downloading</span>
        </div>

        <div class="col-start-5 col-span-4">
          <progress-bar
            v-if="getDownloadProgressPercentage"
            :value="getDownloadProgressPercentage"
            :show-value="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";
import store from "../../vuex/store"
import {createNamespacedHelpers} from "vuex"
import ProgressBar from 'primevue/progressbar';

const {mapState: streamSeatState} = createNamespacedHelpers('streamSeat')
const {mapActions: webSocketActions} = createNamespacedHelpers('webSocket')
const {mapActions: streamSeatAction} = createNamespacedHelpers('streamSeat')
const {mapGetters: streamSeatGetters} = createNamespacedHelpers('streamSeat')

const {mapMutations: streamSeatMutations} = createNamespacedHelpers('streamSeat')
export default {
  name: "DownloadVideo",
  components: {
    ProgressBar
  },
  beforeRouteEnter (to, from, next) {
    const streamVideoRecordID = store.state.streamSeat.stream_video_id
    if (!streamVideoRecordID) {
      next(vm => {
        vm.$notify({
          type: 'error',
          title: 'Error',
          message: 'Download url not available'
        })
        vm.$router.push(from.path)
      })
    } else {
      next()
    }
  },
  data: function () {
    return {
      checkProgressInterval: null
    }
  },
  computed: {
    ...streamSeatState({
      downloadUrl: state => state.download_url,
      streamVideoId: state => state.stream_video_id,
      is_downloading: state => state.is_downloading,
    }),
    ...streamSeatGetters({
      getDownloadProgressPercentage: 'getDownloadProgressPercentage',
      hasFinishedDownload: 'hasFinishedDownload'
    })
  },
  mounted() {
    if (!this.is_downloading) {
      this.downloadVideo()
    }

    // TODO: remove the interval once the video has been downloaded
    //  how we know this ?? when the size downloaded is equal the total size

    // check the download progress every 3 s
    const local = this
    this.checkProgressInterval = setInterval(function() {
      // TODO: push the user to the editor
      local.getDownloadProgress().then((response) => {
        if (response.data.error) {
          clearInterval(local.checkProgressInterval)
          local.$notify({
            title: 'Error',
            type: 'error',
            text: response.data.error
          })
        }
      })

      if(local.hasFinishedDownload) {
        clearInterval(local.checkProgressInterval)
        router.push({name: 'StreamHome'})
        // move to the editor page with
        // we push to editor page though we should have only one page for both
        // video seat and editor seat
        // router.push()
      }
    }, 3000);
  },
  beforeUnmount () {
    clearInterval(this.checkProgressInterval)
  },
  methods: {
    ...streamSeatAction({
      downloadVideo: 'downloadVideo',
      getDownloadProgress: 'getDownloadProgress'
    }),
    ...webSocketActions({
      initConnection: 'init'
    }),
    ...streamSeatMutations({
      setIsDownloading: 'setIsDownloading',
    })
  },
}
</script>

<style lang="scss">
  .p-progressbar-label, .p-progressbar-value {
    --tw-text-opacity: 1 !important;
    font-size: 0.75rem !important;
    //font-size: 1rem !important;
    font-weight: 700 !important;
  }
  .p-progressbar-indeterminate{
  }
  .p-progressbar .p-progressbar-value {
    background-color: rgba(0,180,255,var(--tw-bg-opacity)) !important;
  }
</style>
