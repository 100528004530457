<template>
  <div
    class="flex h-screen"
  />
</template>

<script>
import router from "../../router"
import {createNamespacedHelpers} from 'vuex'

const {mapMutations} = createNamespacedHelpers('video')
const {mapState: videoSeatState} = createNamespacedHelpers('videoSeat')
const {mapMutations: editorMutations} = createNamespacedHelpers('editor')
const {mapActions: videoSeatActions} = createNamespacedHelpers('videoSeat')
const {mapMutations: videoSeatMutations} = createNamespacedHelpers('videoSeat')

export default {
  name: 'BaseComponent',
  components: {
  },

  data: function () {
    return {
      videoInputs: {
        url: null,
        type: null,
        duration: null,
        fast_url: null,
        user_id: null,
        seat_id: null,
      },
      record: null
    }
  },

  computed: {
    ...videoSeatState({
      url: state => state.url,
      type: state => state.type,
      duration: state => state.duration,
      fast_url: state => state.fast_url,
      seat_id: state => state.seat_id,
    }),
    ffmpegTrimInterval() {
      return this.ffmpegData.trim_interval
    },
    videoId() {
      return this.$route.params.id
    }
  },

  watch: {},

  created: function () {
    this.getParametersFromUrl()

    //  if not found create the record with the options
    this.getRecordById().then((response) => {
      this.record = response.data.record

      if (this.shouldRedirectToSessionExpiredPage()) {
        router.push({ name: 'session-expired' })
        return
      }

      if (this.shouldRedirectToEditor()) {
        this.goToEditor()
        return;
      }

      if (this.shouldGoToPreview()) {
        router.push({ name: 'preview' })
      }
    })
  },

  mounted() {
  },

  methods: {
    ...mapMutations({
      setVideo: 'setVideo'
    }),
    ...videoSeatMutations({
      setData: 'setData',
    }),
    ...videoSeatActions({
      getRecordById: 'getRecordById',
      initRecord: 'initRecord'
    }),
    ...editorMutations({
      setOptions: 'setOptions',
      setIsStream: 'setIsStream',
    }),
    /**
     * @description temporary fetch of needed inputs from url
     * the url parameters will be the following:
     *  url
     *  type
     *  duration
     *  fast_url this will be the low quality video which we will set as the display
     */
    getParametersFromUrl: function () {
      // For now get parameters from url
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);

      // video-seat data
      const url = params.get('url')
      const type = params.get('type')
      const duration = params.get('duration');
      const fast_url = params.get('fast_url');
      const seat_id = params.get('seat_id');

      this.setData({
        url: url,
        type: type,
        duration: duration,
        fast_url: fast_url,
        seat_id: seat_id,
      })
    },
    setVideoFun: function () {
      this.setVideo({
        // url: 'https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8',
        // url: 'http://localhost:3080/public/downloaded_videos/sample.mp4',
        // url: 'https://alkassnet.hibridcdn.net/vod/811b63fe-e7ca-431e-9c45-cd7383f7bd1d/smil:DSEwYyKRgbTCXFGD.smil/720p/video.mp4_chunk.m3u8',
        url: this.url,
        // type: 'application/x-mpegurl',
        // type: 'video/mp4',
        type: this.type,
        // duration: 30,
        // duration: 378,
        duration: this.duration,
        fast_url: this.fast_url,
      })
    },
    shouldRedirectToSessionExpiredPage: function () {
      return (this.record && this.record.is_deactivated)
    },
    shouldRedirectToEditor: function () {
      // && !this.record.exception_message
      // why stopping from editor page because of the exception message
      return (this.record  && !this.record.is_deactivated && ((this.record.status === 'DONE' && this.record.is_state_saved ) || (this.record.status !== 'PROCESSING' && this.record.status !== 'DONE'))) || (!this.record)
    },
    shouldGoToPreview: function () {
      return (this.record && !this.record.exception_message && !this.record.is_deactivated && this.record.status !== null && !this.record.is_published && !this.record.is_state_saved)
    },
    goToEditor: function () {
      // if record don't exist create record and go to editor
      this.setIsStream(false)
      this.setVideoFun()

      if (!this.record) {
        this.initRecord().then(() => {
          router.push({ name: 'editor' })
        })
      } else {
        // set the default options if exists
        this.setOptions(this.record.options)
        router.push({ name: 'editor' })
      }
    }
  }
}
</script>

<style scoped>

</style>
