<template>
  <div>
    <transition
      name="fade"
      appear
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "StreamVideos"
}
</script>

<style scoped>

</style>
