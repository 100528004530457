import http from '../http-common'

class StreamSeatService {
  getRecordByStreamId (streamId) {
    return http.get(`/api/stream-seat/get-record-by-stream-id/${streamId}`)
  }

  downloadVideo (data) {
    return http.post('/api/stream-seat/download-video', data)
  }

  createRecord (data) {
    return http.post('/api/stream-seat/create-record', data)
  }

  getDownloadProgress (streamVideoId) {
    return http.get(`/api/stream-seat/get-download-progress/${streamVideoId}`)
  }

  setOptions (streamVideoId, data) {
    return http.put(`/api/stream-seat/set-options/${streamVideoId}`, data)
  }

  cancelRecord (streamVideoId) {
    return http.put(`/api/stream-seat/cancel-record/${streamVideoId}`)
  }

  deleteRecord (streamVideoId) {
    return http.delete(`/api/stream-seat/delete-record/${streamVideoId}`)
  }

  publishRecord (recordId) {
    return http.put(`/api/stream-seat/publish-record/${recordId}`)
  }

  isRecordValid (recordId) {
    return http.get(`/api/stream-seat/check-record-validation/${recordId}`)
  }
}

export default new StreamSeatService()
