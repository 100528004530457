import http from '../http-common'

class SharedService {
  uploadImage (data) {
    // eslint-disable-next-line no-undef
    return http.post('/api/shared/save-image', data)
  }
}

export default new SharedService()
