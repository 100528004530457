import streamSeatService from '../../api/services/streamSeatService'

const state = {
  seat_id: null,
  download_url: null,
  stream_video_id: null,
  is_downloading: false,
  downloaded_size: null,
  file_total_size: null,
}

const mutations = {
  setData(state, data) {
    state.seat_id = data.seat_id
    state.download_url = data.download_url
  },
  setDownloadUrl(state, url) {
    state.download_url = url
  },
  setSeatId(state, id) {
    state.stream_video_id = id
  },
  setIsDownloading(state, isDownloading) {
    state.is_downloading = isDownloading
  },
  setDownloadSize(state, downloadedSize) {
    state.downloaded_size = downloadedSize
  },
  setFileTotalSize(state, total) {
    state.file_total_size = total
  }
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  getRecord({commit, state}) {
    return streamSeatService.getRecordByStreamId(state.seat_id).then((response) => {
      const record = response.data.seat
      if (record) {
        commit('setSeatId', record.id)
      }
      return new Promise((resolve, reject) => {
        try {
          resolve(response)
        } catch (err) {
          reject(err)
        }
      })
    })
  },
  setData({commit}, data) {
    commit('setData', data)
  },
  downloadVideo({state}) {
    return streamSeatService.downloadVideo({
      id: state.stream_video_id
    })
  },
  createRecord({commit, state}) {
    return streamSeatService.createRecord({
      streamSeatId: state.seat_id,
      downloadUrl: state.download_url
    }).then((response) => {
      commit('setSeatId', response.data.id)
    })
  },
  getDownloadProgress({state, commit}) {
    return streamSeatService.getDownloadProgress(state.stream_video_id).then((response) => {
      commit('setDownloadSize', response.data.progress)
      commit('setFileTotalSize', response.data.length)
      return new Promise((resolve, reject) => {
        try {
          resolve(response)
        } catch (err) {
          reject(err)
        }
      })
    })
  },
  setOptions({state}, options) {
    return streamSeatService.setOptions(state.stream_video_id, {
      options: options
    })
  },
  cancelRecord({state}) {
    return streamSeatService.cancelRecord(state.stream_video_id)
  },
  deleteRecord({state}) {
    return streamSeatService.deleteRecord(state.stream_video_id)
  },
  publishRecord({state}) {
    return streamSeatService.publishRecord(state.stream_video_id)
  },
  isRecordValid({state}) {
    return streamSeatService.isRecordValid(state.stream_video_id)
  }
}

const getters = {
  getData: (state) => {
    return {
      seat_id: state.seat_id,
    }
  },
  getDownloadProgressPercentage: (state) => {
    let per = state.downloaded_size * 100 / state.file_total_size
    per = Math.round(per)
    per = (per < 1 ? 1 : per)
    return per
  },
  hasFinishedDownload: (state) => {
    return state.downloaded_size === state.file_total_size && state.downloaded_size
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
