<template>
  <div>
    <div
      v-for="i in elements"
      :key="i.element_index"
      class="flex items-center mb-2"
    >
      <VideoInputSlider
        v-model:model="i.slider"
        :duration="duration"
        :start="min"
        :end="max"
        duration-layout="side"
        class="flex-grow inverted"
      />
      <div
        class="flex-none w-10 px-3 cursor-pointer"
        @click="decrement(i.element_index)"
      >
        <svg
          id="Layer_1"
          width="25"
          height="25"
          viewBox="0 0 418 418"
        >
          <g>
            <path
              fill="#E5E7EB"
              d="M265.616,38V0H152.384v38H69v60h280V38H265.616z M182.384,30h53.232v8h-53.232V30z"
            />
            <polygon
              points="109,418 309,418 328.016,128 89.984,128"
              fill="#E5E7EB"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import VideoInputSlider from "./VideoInputSlider"
const { mapState } = createNamespacedHelpers('video')

export default {
  name: "CloneableCutOut",

  components: {
    VideoInputSlider
  },

  props: {
    durationLayout: {
      type: String,
      default () {
        return 'top'
      }
    },
    minMax: {
      type: Array,
      default () {
        return []
      }
    },
    sliders: {
      type:Array,
      default () {
        return []
      }
    }
  },

  emits: ['update:sliders'],


  data: function () {
    return {
    }
  },
  computed: {
    ...mapState({
      duration: state => state.video.duration,
    }),
    min () {
      if (this.minMax && this.minMax.length) {
        return this.minMax[0]
      }
      return null
    },
    max () {
      if (this.minMax && this.minMax.length) {
        return this.minMax[1]
      }
      return null
    },
    elements () {
      return this.sliders
    },
    index () {
      if (this.sliders.length) {
        return Math.max.apply(Math, this.sliders.map(function(o) { return o.element_index; })) + 1
      }else {
        return 1
      }
    }
  },
  watch: {
  },

  mounted() {
  },

  methods: {
    increment: function () {
      // increment the model that we inherit
      let elements = this.elements.slice(0)
      let index = this.index + 1

      elements.push({
        element_index: index,
        slider: null
      })

      this.$emit('update:sliders', elements)
    },

    decrement: function (index) {
      let elements = this.elements.slice(0)

      elements = elements.filter(function (obj) {
        return obj.element_index !== index
      })

      this.$emit('update:sliders', elements)
    },
  }
}
</script>

<style scoped>

</style>
