<template>
  <div class="w-full">
    <div class="flex w-full">
      <label class="w-5/12 select-none">
        <input
          v-model="model.active"
          type="checkbox"
          class="text-blue-450 focus:ring-0"
        >
        <span
          class="font-semibold text-xs text-black-victor ml-2"
          :class="[opacityClass]"
        >{{ label }}</span>
      </label>

      <div class="w-7/12">
        <div class="flex justify-between">
          <TimePickerComponent
            v-model:time-model="model.time"
            :active="model.active"
          />
          <input
            v-model="model.color"
            :class="[opacityClass, cursorPerActiveClass]"
            class="w-6 flex-none"
            type="color"
            name="head"
            :disabled="!model.active"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimePickerComponent from "./TimePickerComponent";

export default {
  name: "FadeInOutComponent",
  components: {
    TimePickerComponent
  },
  props: {
    label: {
      type: String,
      default () {
        return 'Fade'
      }
    },
    fadeInOutModel: {
      type: Object,
      default() {
        return {
          active: false,
          time: 1,
          color: '#000000',
        }
      },
    },
  },
  emits: ['update:fadeInOutModel'],
  data: function () {
    return {
      model: {
        active: false,
        time: 1,
        color: '#000000'
      },
    }
  },
  computed: {
    time () {
      return this.model.time.toFixed(2)
    },
    textClass () {
      return this.model.active ? 'text-gray-700' : 'text-gray-300'
    },
    opacityClass () {
      return this.model.active ? 'opacity-100' : 'opacity-30'
    },
    cursorPerActiveClass () {
      return this.model.active ? 'cursor-pointer' : 'cursor-not-allowed'
    }
  },
  watch: {
    model: {
      handler(val){
        this.$emit('update:fadeInOutModel', val)
      },
      deep: true
    },
  },
  mounted() {
    this.model = this.fadeInOutModel
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
