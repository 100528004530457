<template>
  <div>
    <div
      class="flex h-screen"
    >
      <div class="my-auto w-full">
        <div class="bg-white pt-1 rounded victors-fixed-width mx-auto gap-y-0">
          <div class="w-full px-5 flex justify-between items-center">
            <img
              class="inline-block"
              width="77"
              height="auto"
              src="@/assets/images/video-editor-logo.png"
              alt="video-editor"
            >
            <button
              @click="onCancelClick"
            >
              <i class="pi pi-times cursor-pointer text-gray-600 text-lg" />
            </button>
          </div>

          <div class="gap-7 p-5 pt-0 gap-y-px ">
            <div class="flex w-full mt-2">
              <div class="w-2/3 victors-fixed-video relative mr-2">
                <video-player
                  reference="mainVideo"
                />
              </div>
              <div class="w-1/3 pl-3">
                <div class="text-black-victor font-bold text-base select-none">
                  Watermark
                </div>

                <div class="grid grid-cols-2 max-w-sm mt-1">
                  <label class="flex items-center select-none">
                    <input
                      v-model="ffmpegData.has_watermark"
                      class="text-blue-450 focus:ring-0"
                      type="checkbox"
                      name="options"
                      checked
                    >
                    <span
                      class="ml-2 text-xs font-semibold"
                      :class="[opacityClass]"
                    >
                      Watermark
                    </span>
                  </label>

                  <input-file
                    v-model:watermarkImage="watermark_image"
                    name="watermark-image"
                    :model="watermark_image"
                    :default="ffmpegData.watermark_image_url"
                    class="bg-gray-100 max-h-32"
                    :class="[opacityClass]"
                  />
                </div>

                <div class="grid grid-cols-2 max-w-sm mt-3">
                  <span
                    :class="[opacityClass]"
                    class="text-xs font-semibold text-gray-victor"
                  >
                    Position
                  </span>

                  <div
                    :class="[opacityClass]"
                    class="p-2 py-1 bg-gray-lightGrey"
                  >
                    <div
                      v-for="(chunk,index) in watermark_positions"
                      :key="index"
                      class="flex justify-around my-1"
                    >
                      <input
                        v-for="(item, itemIndex) in chunk"
                        :key="itemIndex"
                        v-model="ffmpegData.watermark_position"
                        :class="[borderClass]"
                        type="radio"
                        name="watermark_position"
                        :disabled="!ffmpegData.has_watermark"
                        :value="item"
                        class="text-blue-450 focus:ring-0 focus:border-0 w-3.5 h-3.5 border-gray-300 appearance-none checked:bg-amber-200 focus:shadow-none focus:ring-offset-0"
                      >
                    </div>
                  </div>
                </div>

                <div class="w-full mt-3">
                  <span
                    :class="[opacityClass]"
                    class="text-xs font-semibold select-none text-gray-victor"
                  >
                    Appearance
                  </span>

                  <div class="mt-3">
                    <VideoInputSlider
                      v-if="ffmpegData.video_duration"
                      v-model:model="ffmpegData.watermark_interval"
                      v-model:active="ffmpegData.has_watermark"
                      :duration="ffmpegData.video_duration"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full mt-4">
              <div class="w-2/3 pr-3">
                <div class="w-full">
                  <div class="text-black-victor font-bold text-base select-none">
                    Trim
                  </div>

                  <div class="mt-2">
                    <VideoInputSlider
                      v-if="ffmpegData.video_duration"
                      v-model:model="ffmpegData.trim_interval"
                      :duration="ffmpegData.video_duration"
                      duration-layout="side"
                    />
                  </div>
                </div>

                <div class="w-full mt-6">
                  <div
                    class="text-black-victor font-bold text-base select-none cursor-pointer"
                    @click="$refs.cloneableComponent.increment()"
                  >
                    Cut Out
                    <img
                      class="inline-block mb-1 ml-3"
                      width="15"
                      height="15"
                      src="@/assets/images/add-plus-svgrepo-com.svg"
                      alt="add-cut-out"
                    >
                  </div>

                  <CloneableCutOut
                    ref="cloneableComponent"
                    v-model:sliders="ffmpegData.cut_out_interval"
                    :min-max="ffmpegData.trim_interval"
                    class="mt-2 max-h-32 overflow-y-scroll"
                  />
                </div>
              </div>
              <div class="w-1/3 pl-3 relative min-h-52">
                <div class="text-black-victor font-bold text-base select-none pb-1">
                  Video Fade
                </div>

                <FadeInOutComponent
                  :key="ffmpegData.fade_in_model"
                  v-model:fadeInOutModel="ffmpegData.fade_in_model"
                  label="Fade In"
                />

                <FadeInOutComponent
                  :key="ffmpegData.fade_out_model"
                  v-model:fadeInOutModel="ffmpegData.fade_out_model"
                  class="mt-4"
                  label="Fade Out"
                />

                <div class="flex mt-6 mb-2">
                  <div class="text-black-victor font-bold text-base select-none">
                    Audio Fade
                  </div>
                  <div class="flex pl-3">
                    <div class="form-check form-switch flex items-center">
                      <Toggle
                        v-model="isFadeSyncWithVideo"
                        :classes="{
                          toggle: 'flex w-6 h-3 rounded-full relative cursor-pointer transition items-center box-content border-2 text-xs leading-none',
                          handle: 'inline-block bg-white w-3 h-3 top-0 rounded-full absolute transition-all',
                          toggleOn: 'bg-blue-450 border-blue-450 justify-start text-white',
                          toggleOff: 'bg-gray-200 border-gray-200 justify-end text-gray-700',
                        }"
                        class="focus:ring-0"
                        @change="onSyncFadeToggleChange"
                      />
                      <label
                        :class="[isFadeSyncWithVideo ? 'opacity-100' : 'opacity-50']"
                        class="pl-1 form-check-label inline-block text-black-victor text-xs font-semibold"
                      >
                        Sync with video
                      </label>
                    </div>
                  </div>
                </div>

                <AfadeInOutComponent
                  :key="ffmpegData.a_fade_in_model"
                  v-model:aFadeInOutModel="ffmpegData.a_fade_in_model"
                  :active="ffmpegData.a_fade_in_model.active"
                  label="Fade In"
                />

                <AfadeInOutComponent
                  :key="ffmpegData.a_fade_out_model"
                  v-model:aFadeInOutModel="ffmpegData.a_fade_out_model"
                  :active="ffmpegData.a_fade_out_model.active"
                  class="mt-4"
                  label="Fade Out"
                />

                <div
                  class="text-white w-full h-10 mt-10"
                >
                  <div class="grid grid-cols-2 gap-4">
                    <button
                      class="text-white h-10 bg-amber-500 rounded font-bold text-xs"
                      @click="onCancelClick"
                    >
                      Cancel
                    </button>

                    <button
                      class="text-white h-10 rounded font-bold text-xs"
                      :class="[isPreviewBtnDisabled ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-450']"
                      :disabled="isPreviewBtnDisabled"
                      @click="validateAndSubmit"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { mapState: editorState } = createNamespacedHelpers('editor')
const { mapGetters: videoGetter } = createNamespacedHelpers('video')
const { mapActions: editorActions } = createNamespacedHelpers('editor')
const { mapActions: mapWindowActions } = createNamespacedHelpers('window')
const { mapGetters: videoSeatGetters} = createNamespacedHelpers('videoSeat')
const { mapActions: videoSeatActions } = createNamespacedHelpers('videoSeat')
const { mapMutations: previewMutations } = createNamespacedHelpers('preview')
const { mapActions: streamSeatActions } = createNamespacedHelpers('streamSeat')

import router from "../router"
import Toggle from '@vueform/toggle'
import { createNamespacedHelpers } from 'vuex'
import VideoPlayer from '../components/VideoPlayer'
import inputFile from "../components/form/inputFile"
import CloneableCutOut from '../components/CloneableCutOut'
import VideoInputSlider from '../components/VideoInputSlider'
import FadeInOutComponent from "../components/FadeInOutComponent"
import AfadeInOutComponent from "../components/AfadeInOutComponent";

export default {
  name: 'Editor',

  components: {
    // CustomModal,
    VideoPlayer,
    VideoInputSlider,
    CloneableCutOut,
    FadeInOutComponent,
    inputFile,
    AfadeInOutComponent,
    Toggle
  },

  data: function () {
    return {
      watermark_positions: [
        [
          ['top', 'left'],
          ['top', 'center'],
          ['top', 'right']
        ],
        [
          ['center', 'left'],
          ['center', 'center'],
          ['center', 'right']
        ],
        [
          ['bottom', 'left'],
          ['bottom', 'center'],
          ['bottom', 'right']
        ]
      ],
      // form data
      ffmpegData: {
        video_url: null,
        video_duration: null,
        has_watermark: false,
        watermark_position: null,
        trim_interval: [],
        watermark_interval: [],
        cut_out_interval: [],
        fade_in_model: {
          active: false,
          time: 1,
          color: '#000000',
        },
        fade_out_model: {
          active: false,
          time: 1,
          color: '#000000',
        },
        a_fade_in_model: {
          active: false,
          time: 1,
        },
        a_fade_out_model: {
          active: false,
          time: 1,
        },
      },
      watermark_image: null,
      // vue picture input
      custom_strings: {
        upload: '<h1>Bummer!</h1>',
        drag: 'Drag Png'
      },
      showModal: true,
      isSocketConnectionEstablished: false,
      isLoading: false,
      preview: false,
      initialVideo: null,
      isSubscribeConfirmed: null,
      isPreviewBtnDisabled: false,
      isFadeSyncWithVideo: false,
      validationInterval: null
    }
  },

  computed: {
    ...videoSeatGetters({
      getSeatData: 'getData'
    }),
    ...editorState({
      options: state => state.options,
      is_stream: state => state.is_stream
    }),
    ...videoGetter({
      getVideoData: 'getData'
    }),
    ffmpegTrimInterval() {
      return this.ffmpegData.trim_interval
    },
    videoId () {
      return this.$route.params.id
    },
    opacityClass() {
      return !this.ffmpegData.has_watermark ? 'opacity-50' : 'opacity-100'
    },
    borderClass () {
      return !this.ffmpegData.has_watermark ? 'border-gray-300' : ''
    },
  },

  watch: {
    'ffmpegData.has_watermark': {
      handler(val){
        if(val === false) {
          this.ffmpegData.watermark_position = null
        } else {
          this.ffmpegData.watermark_position = ['top', 'right']
        }
      }
    },
    'ffmpegData.fade_in_model': {
      handler(val){
        if (this.isFadeSyncWithVideo == true) {
          Object.assign(this.ffmpegData.a_fade_in_model, val);
        }
      },
      deep: true
    },
    'ffmpegData.fade_out_model': {
      handler(val){
        if (this.isFadeSyncWithVideo == true) {
          Object.assign(this.ffmpegData.a_fade_out_model, val);
        }
      },
      deep: true
    },
    'ffmpegData.a_fade_in_model': {
      handler(val){
        if (this.isFadeSyncWithVideo == true) {
          Object.assign(this.ffmpegData.fade_in_model, val);
        }
      },
      deep: true
    },
    'ffmpegData.a_fade_out_model': {
      handler(val){
        if (this.isFadeSyncWithVideo == true) {
          Object.assign(this.ffmpegData.fade_out_model, val);
        }
      },
      deep: true
    },

  },
  /**
   * @description in created at we are starting the websocket connection
   * with server. This will be used to update status of the video
   * Also we are setting the default ffmpegData if we have them in the local storage
   */
  created: function () {
    // on created if we have video_seat_id set the video_record inside vuex and get options
    if (this.options) {
      this.ffmpegData = JSON.parse(this.options)
      this.watermark_image = this.ffmpegData.watermark_image
    } else {
      // video options are available inside the vide vuex state
      // few options we set on default
      this.ffmpegData.video_url = this.getVideoData.url
      this.ffmpegData.video_duration = this.getVideoData.duration
      this.duration = this.getVideoData.duration
    }
  },

  mounted() {
    const local = this
    if (!this.is_stream) {
      this.validationInterval = setInterval(() => {
        local.validateSeat().then(({data}) => {
          if (!data.isValid) {
            router.push({ name: 'session-expired' })
          }
        })
      }, 5000)
    } else {
      this.validationInterval = setInterval(() => {
        local.isRecordValid().then(({data}) => {
          if (!data.isValid) {
            router.push({ name: 'session-expired' })
          }
        })
      }, 5000)
    }
  },

  beforeUnmount() {
    clearInterval(this.validationInterval)
  },

  methods: {
    ...mapWindowActions({
      windowPostDeleteMessage: 'postDeleteMessage'
    }),
    ...videoSeatActions({
      getRecordById: 'getRecordById',
      validateSeat: 'validateSeat',
      saveEditorsOptions: 'saveEditorsOptions',
      cancelVideoRecord: 'cancelVideoRecord',
      deleteRecord: 'deleteRecord'
    }),
    ...editorActions({
      uploadImage: 'uploadImage',
    }),
    ...streamSeatActions({
      setOptions: 'setOptions',
      streamDeleteRecord: 'deleteRecord',
      isRecordValid: 'isRecordValid'
    }),
    ...previewMutations({
      setIsPublished: 'setIsPublished',
      setIsCanceled: 'setIsCanceled'
    }),
    validateAndSubmit: function () {
      this.isPreviewBtnDisabled = true
      if (!this.is_stream) {
        this.validateSeat().then((response) => {
          const data = response.data
          if (!data.isValid) {
            router.push({ name: 'session-expired' })
          } else {
            this.formSubmit()
          }
        })
      } else {
        this.formSubmit()
      }
    },

    /**
     * @description on form submit,
     * we first upload the image get response of the server then we call saveAndPublish
     */
    formSubmit: function () {
      if (this.ffmpegData.has_watermark) {
        this.uploadImage(this.watermark_image).then(({data}) => {
          if (data.imageUrl) {
            this.ffmpegData.watermark_image_url = data.imageUrl
          }
          this.ffmpegData.watermark_image = data.image
          this.saveAndPublish()
        }).catch((error) => {
          this.isPreviewBtnDisabled = false
          let errors = error.response.data.errors
          errors = errors.map(obj => obj.msg)
          this.$notify({
            title: 'Error',
            type: 'error',
            text: errors.join()
          })
        })
      }
      else {
        this.saveAndPublish()
      }
    },

    saveAndPublish: function () {
      // we have different create api for different stream seat and video seat
      // either create the record if vide seat or update the stream seat and add the options
      if (!this.is_stream) {
        this.saveEditorsOptions(this.ffmpegData).then(() => {
          router.push({ name: 'preview' })
        }).catch((error) => {
          this.isPreviewBtnDisabled = false
          let errors = error.response.data.errors
          errors = errors.map(obj => obj.msg)
          this.$notify({
            title: 'Error',
            type: 'error',
            text: errors.join()
          })
        })
      } else {
        this.setOptions(this.ffmpegData).then(() => {
          // TODO: set the required params in preview
          router.push({ name: 'preview' })
        })
      }
    },

    onCancelClick: function () {
      this.$confirm.require({
        message: '',
        header: 'Save before closing?',
        icon: '',
        acceptLabel: 'Save Draft',
        rejectLabel: 'Delete',
        acceptClass: 'text-white bg-amber-500 rounded border-0 w-1/2',
        rejectClass: 'text-white bg-red-600 rounded border-0 w-1/2',
        accept: () => {
          // Save the options in database
          // to save the options we first need to save the image,
          // so we upload the image first then save options
          this.uploadImage(this.watermark_image).then(({data}) => {
            if (data.imageUrl) {
              this.ffmpegData.watermark_image_url = data.imageUrl
            }
            this.ffmpegData.watermark_image = data.image

            if (this.is_stream) {
              this.setOptions(this.ffmpegData).then(() => {
                this.$notify({
                  title: 'Success',
                  type: 'success',
                  text: 'Data Saved Successfully.'
                })
              })
            } else {
              this.saveEditorsOptions(this.ffmpegData).then(() => {
                this.$notify({
                  title: 'Success',
                  type: 'success',
                  text: 'Data Saved Successfully.'
                })
              })
            }
          }).catch(() => {
            this.$notify({
              title: 'Error',
              type: 'error',
              text: 'Something went wrong.'
            })
          })
        },
        reject: () => {
          // Cancel Session
          if (!this.is_stream) {
            // TODO: delete instead of cancel
            this.deleteRecord().then((response) => {
              const data = response.data
              if (data.success) {
                this.windowPostDeleteMessage()
              } else {
                throw new Error(data.message)
              }
            }).catch((err) => {
              this.$notify({
                title: 'Error',
                type: 'error',
                text: err.message
              })
            })
          } else {
            this.streamDeleteRecord().then((response) => {
              const data = response.data
              if (data.success) {
                this.windowPostDeleteMessage()
              } else {
                throw new Error(data.message)
              }
            }).catch((err) => {
              this.$notify({
                title: 'Error',
                type: 'error',
                text: err.message
              })
            })
          }

        }
      });
    },

    onSyncFadeToggleChange: function (val) {
      if (val == true) {
        Object.assign(this.ffmpegData.a_fade_in_model, this.ffmpegData.fade_in_model);
        Object.assign(this.ffmpegData.a_fade_out_model, this.ffmpegData.fade_out_model);
      }
    }
  },
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style scoped>
</style>
