import video from "./modules/video";
import window from "./modules/window";
import VideoSeat from "./modules/video-seat";
import webSocket from "./modules/web-socket";
import StreamSeat from "./modules/stream-seat";
import { createLogger, createStore } from 'vuex'
import Editor from "./modules/editor";


const store = createStore({
  plugins: [createLogger()],
  modules: {
    video: video,
    videoSeat: VideoSeat,
    streamSeat: StreamSeat,
    webSocket: webSocket,
    editor: Editor,
    window: window,
  }
})

export default store
