import Editor from "../views/Editor";
import Preview from "../views/Preview";
import Home from '../views/video/Home.vue';
import StreamHome from '../views/streams/Home';
import SessionExpired from "../views/SessionExpired";
import StreamVideos from "../views/streams/StreamVideos";
import DownloadVideo from "../views/streams/DownloadVideo";
import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
  {
    path: '/:id?',
    name: 'home',
    component: Home
  },
  {
    path: '/editor',
    name: 'editor',
    component: Editor,
  },
  {
    path: '/preview',
    name: 'preview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Preview,
  },
  {
    path: '/session-expired',
    name: 'session-expired',
    component: SessionExpired
  },
  {
    path: '/stream-videos',
    component: StreamVideos,
    children: [
      {
        path: '',
        name: 'StreamHome',
        component: StreamHome
      },
      {
        path: '/download',
        name: 'DownloadVideo',
        component: DownloadVideo
      }
    ]
  },
]

const router = createRouter({
  // eslint-disable-next-line no-undef
  // history: createWebHistory(process.env.BASE_URL),
  // eslint-disable-next-line no-undef
  history: createWebHashHistory(process.env.BASE_URL),
  mode: 'hash',
  routes
})

// router.beforeEach((to, from, next) => {
//   next()
// })

export default router
