<template>
  <div class="flex h-screen">
    <div class="my-auto w-full">
      <div
        class="text-center "
      >
        <div
          class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-400 hover:bg-rose-500 focus:border-rose-700 active:bg-rose-700 transition ease-in-out duration-150 cursor-not-allowed"
        >
          <span
            v-if="queryMessage"
            v-text="queryMessage"
          />
          <span
            v-else
            v-text="'Session Expired'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SessionExpired",
  data: function () {
    return {

    }
  },

  computed: {
    queryMessage() {
      return this.$route.query.message
    }
  },

  created: function () {
  },

  mounted() {
  },


}
</script>

<style scoped>

</style>
