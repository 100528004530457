const state = {}

const mutations = {}

const actions = {
  postDeleteMessage() {
    // eslint-disable-next-line no-undef
    console.log(process.env.VUE_APP_PARENT_WINDOW_URL)
    // eslint-disable-next-line no-undef
    window.parent.postMessage({ message: 'delete-session'}, process.env.VUE_APP_PARENT_WINDOW_URL)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
