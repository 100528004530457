<template>
  <div class="flex justify-between">
    <div
      :class="[opacityClass]"
      class="w-6 h-6 text-2xl leading-none text-center -m-px cursor-pointer select-none bg-gray-lightGrey text-black-iconBlack"
      @click="decrement"
    >
      -
    </div>

    <div class="w-14 text-center font-black text-xs">
      <div class="text-sm">
        <input
          v-model="model"
          type="number"
          step="0.01"
          :class="[opacityClass]"
          class="border-0 w-2/6 no-arrows font-semibold text-xs text-black-victor text-center p-0 focus:border-0 focus:outline-none focus:ring-0"
        >
        <span
          :class="[opacityClass]"
          class="text-xs text-black-victor font-semibold"
        >
          sec
        </span>
      </div>
    </div>

    <div
      :class="[opacityClass]"
      class="w-6 h-6 text-2xl leading-none text-center -m-px cursor-pointer select-none bg-gray-lightGrey text-black-iconBlack"
      @click="increment"
    >
      +
    </div>
  </div>
</template>

<script>
export default {
  name: "TimePickerComponent",
  props: {
    timeModel: {
      type: Number,
      default() {
        return 1
      },
    },
    active: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  emits: ['update:timeModel'],
  data: function () {
    return {
      model: 1
    }
  },
  computed: {
    borderClass () {
      return !this.active ? 'border-gray-300' : ''
    },
    opacityClass () {
      return this.active ? 'opacity-100' : 'opacity-30'
    },
  },
  watch: {
    model: {
      handler(val) {
        this.$emit('update:timeModel', val)
      }
    },
    timeModel: {
      handler(val) {
        this.model = val
      },
      deep: true
    },
  },
  mounted() {
    this.model = this.timeModel
  },
  methods: {
    increment: function () {
      this.model += 1
    },
    decrement: function () {
      if(this.model > 1)
        this.model -= 1
    }
  }

}
</script>

<style scoped>

</style>
