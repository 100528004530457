<template>
  <div
    :class="inputClass"
    class="w-full h-full bg-gray-lightGrey relative cursor-pointer"
    @click="openInputFile"
  >
    <div class="w-full victors-fixed-watermark-image p-4 flex justify-center">
      <img
        v-if="!image"
        alt="image"
        src="@/assets/images/gallery-image.svg"
        class="w-full h-full"
      >

      <img
        v-else
        alt="image"
        :src="image"
        class="w-auto h-full"
      >
    </div>

    <input
      ref="file"
      class="cursor-pointer absolute block opacity-0 pin-r pin-t top-0 left-0 w-full h-full"
      type="file"
      :name="name"
      @change="fileChange($event)"
    >
  </div>
</template>

<script>
export default {
  name: 'InputFile',

  props: {
    name: {
      type: String,
      default () {
        return ''
      }
    },

    inputClass: {
      type: String,
      default () {
        return 'model'
      }
    },

    model: {
      type: String,
      default () {
        return ''
      }
    },

    default: {
      type: String,
      default () {
        return ''
      }
    }
  },
  emits: ['fileChange', 'update:watermarkImage'],

  data () {
    return {
      placeholder: null,
      image: null
    }
  },

  mounted () {
    this.placeholder = this.name

    // set the default if default exist
    if (this.default) {
      this.image = this.default
    }
  },

  methods: {
    fileChange: function (event) {
      const file = event.target.files[0];
      this.placeholder = file.name
      this.image = URL.createObjectURL(file)
      this.$emit('update:watermarkImage', event.target.files[0])
    },
    openInputFile: function () {
      this.$refs.file.click()
    }
  }

}
</script>

<style scoped>

</style>
