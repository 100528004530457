<template>
  <div class="flex h-screen">
    <div class="my-auto w-full">
      <div v-if="preview">
        <div class="grid grid-cols-8">
          <div class="h-96 relative col-start-3 col-span-4">
            <video-player
              reference="PreviewVideo"
            />
            <div class="flex flex-row-reverse mt-5">
              <button
                class="bg-blue-450 hover:bg-blue-500 text-white font-bold text-xs py-2 px-4 rounded mx-1"
                @click="publish"
              >
                Publish
              </button>
              <button
                class="bg-amber-500 hover:bg-amber-400 text-white font-bold text-xs py-2 px-4 rounded mx-1"
                @click="reset"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="text-center"
      >
        <div
          class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-400 hover:bg-rose-500 focus:border-rose-700 active:bg-rose-700 transition ease-in-out duration-150 cursor-not-allowed"
        >
          <svg
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span
            v-text="status"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router"
import { createNamespacedHelpers } from "vuex"
import VideoPlayer from '../components/VideoPlayer'

const { mapMutations } = createNamespacedHelpers('video')
const { mapState: editorState } = createNamespacedHelpers('editor')
const { mapState: webSocketState} = createNamespacedHelpers('webSocket')
const { mapState: videoSeatState} = createNamespacedHelpers('videoSeat')
const { mapState: streamSeatState} = createNamespacedHelpers('streamSeat')
const { mapActions: mapWindowActions } = createNamespacedHelpers('window')
const { mapActions: mapVideoActions } = createNamespacedHelpers('videoSeat')
const { mapActions: streamSeatActions } = createNamespacedHelpers('streamSeat')
const { mapMutations: webSocketMutations } = createNamespacedHelpers('webSocket')


export default {
  name: "Preview",
  components: {
    VideoPlayer,
  },
  data: function () {
    return {
      status: 'Processing',
      preview: false,
      video: null,
      pingInterval: null
    }
  },
  computed: {
    ...webSocketState({
      connection: state => state.connection,
      isSocketConnectionEstablished: state => state.isSocketConnectionEstablished,
    }),
    ...videoSeatState({
      video_seat_id: state => state.video_seat_id
    }),
    ...streamSeatState({
      stream_video_id: state => state.stream_video_id
    }),
    ...editorState({
      is_stream: state => state.is_stream
    }),
  },
  created: function () {
    // websocket connection
    this.initConnection()

    let self = this
    this.connection.onopen = function() {
      self.pingSocketConnection()
      if (!self.is_stream) {
        self.connection.send(JSON.stringify({request:'SUBSCRIBE_BY_ID', data: { id: self.video_seat_id}}));
      } else {
        self.connection.send(JSON.stringify({request:'SUBSCRIBE_STREAM_BY_ID', data: { id: self.stream_video_id}}));
      }
    }

    this.connection.onmessage = (event) => {
      this.onSocketMessage(event)
    }

    this.connection.onclose = () => {
      console.log('connection is closed')
    }

    // add ping to connection just to keep it alive every 20 seconds


  },
  mounted() {
  },
  methods: {
    ...mapMutations({
      setVideo: 'setVideo'
    }),
    ...webSocketMutations({
      initConnection: 'init'
    }),
    ...mapWindowActions({
      windowPostDeleteMessage: 'postDeleteMessage'
    }),
    ...mapVideoActions({
      cancelVideoRecord: 'cancelVideoRecord',
      publishRecord: 'publishRecord',
      getRecordById: 'getRecordById',
    }),
    ...streamSeatActions({
      cancelStreamRecord: 'cancelRecord',
      seatPublishRecord: 'publishRecord'
    }),
    onSocketMessage: function (event) {
      let json = event.data
      json = JSON.parse(json)
      switch (json.request) {
        case 'CHANGE_STATUS':
          this.status = json.data.status
          break

        case 'SYN_SUBSCRIBE':
          // it means that server received the subscription
          // send back the confirmation that client received the SYN
          // this.connection.send(JSON.stringify({request:'SYN', data: {video_id: json.data.video_id}}));
          // also go to the preview page where we keep track of our video by job id
          break

        case 'PREVIEW':
          this.stopPingConnection()
          this.preview = true
          this.video = json.data.video
          this.setVideo({
            url: (this.is_stream ? this.video.output_url : this.video.output),
            duration: 0, // for now, we don't need this option
            type: 'video/mp4'
          })
          // self.callRemoteVideos(json.data.url)
          break

        case 'EXCEPTION':
          // go back to editor with error
          this.stopPingConnection()
          this.$notify({
            title: 'Error',
            type: 'error',
            text: 'We have encountered errors converting your video.'
          })
          if (this.is_stream) {
            router.push({name: 'StreamHome'})
          } else {
            router.push({ name: 'home' })
          }
      }
    },

    reset: function () {
      // cancel video
      if (!this.is_stream) {
        this.cancelVideoRecord().then((response) => {
          const data = response.data
          if (data.success) {
            this.setVideo(JSON.parse(this.video.inputs))
            router.push({ name: 'home' })
          } else {
            throw new Error('Something went wrong')
          }
        }).catch((error) => {
          this.$notify({
            title: 'Error',
            type: 'error',
            text: error.message
          })
        })
      } else {
        this.cancelStreamRecord().then(() => {
          this.setVideo({
            duration: this.video.duration,
            url: this.video.video_url,
            type: 'video/mp4',
            fast_url: this.video.video_url
          })
          router.push({name: 'StreamHome'})
        }).catch((error) => {
          this.$notify({
            title: 'Error',
            type: 'error',
            text: error.message
          })
        })
      }
    },

    publish: function () {
      if (!this.is_stream) {
        this.publishRecord().then(() => {
          this.windowPostDeleteMessage()
        })
      } else {
        this.seatPublishRecord().then(() => {
          this.windowPostDeleteMessage()
        })
      }
    },

    pingSocketConnection: function () {
      const local = this
      if (this.pingInterval) {
        clearInterval(this.pingInterval)
      }
      this.pingInterval = setInterval(() => {
        local.connection.send(JSON.stringify({request:'PING', data:{}}))
      }, 20000)
    },

    stopPingConnection: function () {
      if (this.pingInterval) {
        clearInterval(this.pingInterval)
      }
    }
  },
}
</script>

<style scoped>

</style>
