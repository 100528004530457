<template>
  <div class="bg-gray-450 h-screen">
    <router-view />
  </div>

  <notifications
    position="top right"
    classes="vue-notification"
  />

  <ConfirmDialog />
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog'

export default {
  name: 'App',
  components: {
    ConfirmDialog
  }
}
</script>

<style lang="scss">
@import "./assets/css/main";
</style>
<!--<template>-->
<!--  <div id="nav">-->
<!--    <router-link to="/">-->
<!--      Home-->
<!--    </router-link> |-->
<!--    <router-link to="/about">-->
<!--      About-->
<!--    </router-link>-->
<!--  </div>-->
<!--  <router-view />-->
<!--</template>-->

<!--<style lang="scss">-->
<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--}-->

<!--#nav {-->
<!--  padding: 30px;-->

<!--  a {-->
<!--    font-weight: bold;-->
<!--    color: #2c3e50;-->

<!--    &.router-link-exact-active {-->
<!--      color: #42b983;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
