const state = {
  connection: null,
  isSocketConnectionEstablished: false
}

const mutations = {
  init(state){
    // eslint-disable-next-line no-undef
    state.connection = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}`)
    state.connection.onopen = function (event) {
      console.log("Successfully connected to the echo websocket server and now we can serve... event:", event)
      state.isSocketConnectionEstablished = true
    }
  }
}

const actions = {
  init() {
    // eslint-disable-next-line no-undef
    state.connection = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}`)
    return new Promise((resolve, reject) => {
      state.connection.onopen = resolve
      state.connection.onerror = reject;
      state.connection.onclose = reject;
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
